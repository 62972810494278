export default {
  "Вход в кабинет": "Вход в кабинет",
  ПИНФЛ: "ПИНФЛ",
  "Серия и номер документа": "Серия и номер документа",
  "Место выдачи": "Место выдачи",
  "Адрес по прописке": "Адрес по прописке",
  "Адрес проживания": "Адрес проживания",
  "Дополнительный контактный номер": "Дополнительный контактный номер",
  Добавить: "Добавить",
  "Семейный статус": "Семейный статус",
  Образование: "Образование",
  "Опыт работы": "Опыт работы",
  Ожидается: "Ожидается",
  "Сотрудники (HRM)": "Сотрудники (HRM)",
  Подразделения: "Подразделения",
  Должности: "Должности",
  Сотрудники: "Сотрудники",
  Доступы: "Доступы",
  "Компании (CRM)": "Компании (CRM)",
  "Задачи (TASKS)": "Задачи (TASKS)",
  KPI: "KPI",
  "Общий KPI компании": "Общий KPI компании",
  Компании: "Компании",
  "Help Desk": "Help Desk",
  Отчетность: "Отчетность",
  Резиденты: "Резиденты",
  Выход: "Выход",
  Назад: "Назад",
  "Добавить номер": "Добавить номер",
  "Добавить почту": "Добавить почту",
  "Уволить сотрудника": "Уволить сотрудника",
  "Назначить должность": "Назначить должность",
  "Персональная информация": "Персональная информация",
  Документы: "Документы",
  "Отпуска и отгулы": "Отпуска и отгулы",
  "История передвижения": "История передвижения",
  Филиалы: "Филиалы",
  "Все филиалы": "Все филиалы",
  "Архивированные филиалы": "Архивированные филиалы",
  Филиал: "Филиал",
  Регион: "Region",
  "Общий рейтинг": "Overall rating",
  "Создать филиал": "Создать филиал",
  Изменить: "Изменить",
  Архивировать: "Архивировать",
  "Поле для поиска": "Поле для поиска",
  Восстановить: "Восстановить",
  "Все подразделения": "Все подразделения",
  "Архивированные подразделения": "Архивированные подразделения",
  "Создать отдел": "Создать отдел",
  "Кол-во сотрудников": "Кол-во сотрудников",
  "Все должности": "Все должности",
  "Архивированные должности": "Архивированные должности",
  "Наименование должности": "Наименование должности",
  "Тип должности": "Тип должности",
  "Тип сотрудника": "Тип сотрудника",
  "Создать должность": "Создать должность",
  Сотрудник: "Сотрудник",
  "Все сотрудники": "Все сотрудники",
  ФИО: "ФИО",
  Подразделение: "Подразделение",
  Должность: "Должность",
  "Добавить сотрудника": "Добавить сотрудника",
  Поиск: "Поиск",
  "По странам": "По странам",
  Выберите: "Выберите",
  "Источник карточки": "Источник карточки",
  Приоритет: "Приоритет",
  Этапы: "Этапы",
  "Просроченные карточки": "Просроченные карточки",
  "Архивированные карточки": "Архивированные карточки",
  "Название компании": "Название компании",
  "Создать этап": "Создать этап",
  Редактировать: "Редактировать",
  "Передвинуть влево": "Передвинуть влево",
  "Передвинуть вправо": "Передвинуть вправо",
  "Срок прохождения": "Срок прохождения",
  день: "день",
  дня: "дня",
  дней: "дней",
  "Добавить компанию": "Добавить компанию",
  "Узнать детали": "Узнать детали",
  "Последние изменения": "Последние изменения",
  "Создать рабочее пространство": "Создать рабочее пространство",
  "У вас отсутствуют созданные рабочие пространства.":
    "У вас отсутствуют созданные рабочие пространства.",
  "Пожалуйста, создайте рабочее пространство для начала работы.":
    "Пожалуйста, создайте рабочее пространство для начала работы.",
  Низкий: "Низкий",
  Высший: "Высший",
  Средний: "Средний",
  "Контактное лицо": "Контактное лицо",
  Источник: "Источник",
  "Прикрепленные сотрудники": "Прикрепленные сотрудники",
  "Локация штаб-квартиры": "Локация штаб-квартиры",
  "Количество офисов в мире": "Количество офисов в мире",
  "Доход (USD)": "Доход (USD)",
  "Количество сотрудников в мире": "Количество сотрудников в мире",
  "Веб-сайт": "Веб-сайт",
  "Дата создания": "Дата создания",
  "Текущий Этап": "Текущий Этап",
  "Важность компании": "Важность компании",
  "Срок прохождения этапа": "Срок прохождения этапа",
  Действия: "Действия",
  "История действий": "История действий",
  "Сменить этап": "Сменить этап",
  "Продлить срок": "Продлить срок",
  "Назначить сотрудника": "Назначить сотрудника",
  "Добавить комментарий": "Добавить комментарий",
  "Архивировать карточку": "Архивировать карточку",
  "Выберите этап": "Выберите этап",
  Отправить: "Отправить",
  "Создание этапа": "Создание этапа",
  "Наименование этапа": "Наименование этапа",
  "Например, Проведение переговоров": "Например, Проведение переговоров",
  Закрыть: "Закрыть",
  "Деятельность компании": "Деятельность компании",
  "Страна учредитель": "Страна учредитель",
  "Скрыть детали": "Скрыть детали",
  Деятельность: "Деятельность",
  Пользователь: "Пользователь",
  Действие: "Действие",
  Дата: "Дата",
  Создан: "Создан",
  Сохранить: "Сохранить",
  "Ответственные сотрудники": "Ответственные сотрудники",
  "Написать комментарий": "Написать комментарий",
  Опубликовать: "Опубликовать",
  "Вы точно хотите архивировать?": "Вы точно хотите архивировать?",
  Да: "Да",
  Нет: "Нет",
  "Переведен на этап": "Переведен на этап",
  Архивирован: "Архивирован",
  "Срок был проделен": "Срок был проделен",
  "Назначен сотрудник": "Назначен сотрудник",
  Комментировал: "Комментировал",
  "Прокомментируйте действие": "Прокомментируйте действие",
  "Добавьте ИНН компании": "Добавьте ИНН компании",
  ИНН: "ИНН",
  "Дата регистрации": "Дата регистрации",
  "Название стратапа": "Название стратапа",
  "ИНН стартапа (необязательное)": "ИНН стартапа (необязательное)",
  "Сумма инвестиций в стартап (USD)": "Сумма инвестиций в стартап (USD)",
  "Вы уверены?": "Вы уверены?",
  "Выберите подразделение": "Выберите подразделение",
  "Название отдела": "Название отдела",
  "Напишите отдел": "Напишите отдел",
  "Руководитель отдела": "Руководитель отдела",
  "Количество сотрудников": "Количество сотрудников",
  Введите: "Введите",
  Отмена: "Отмена",
  "Создание отдела": "Создание отдела",
  "Название филиала": "Название филиала",
  "Выберите регион": "Выберите регион",
  "Создание филиала": "Создание филиала",
  "Напишите филиал": "Напишите филиал",
  "Название должности (на узбекском языке)":
    "Название должности (на узбекском языке)",
  "Поле для ввода": "Поле для ввода",
  "Выберите тип должности": "Выберите тип должности",
  "Создание должности": "Создание должности",
  Отдел: "Отдел",
  "Выберите отдел": "Выберите отдел",
  "Название должности (на английском)": "Название должности (на английском)",
  "Добавление сотрудника": "Добавление сотрудника",
  "Серия и номер паспорта": "Серия и номер паспорта",
  "Дата рождение": "Дата рождение",
  год: "год",
  месяц: "месяц",
  Далее: "Далее",
  "Дата рождения": "Дата рождения",
  Пол: "Пол",
  Мужской: "Мужской",
  Женский: "Женский",
  "Подтвердите действие": "Подтвердите действие",
  "Вы точно хотите уволить сотрудника?": "Вы точно хотите уволить сотрудника?",
  Назначение: "Назначение",
  "Номер телефона": "Номер телефона",
  Наименование: "Наименование",
  "ВАШИ РАБОЧИЕ ПРОСТРАНСТВА": "ВАШИ РАБОЧИЕ ПРОСТРАНСТВА",
  "Создать доску": "Создать доску",
  "Добавить рабочее пространство": "Добавить рабочее пространство",
  Фильтры: "Фильтры",
  "Добавить задачу": "Добавить задачу",
  Фильтр: "Фильтр",
  "Очистить все": "Очистить все",
  "Применить фильтры": "Применить фильтры",
  "Добавление задачи": "Добавление задачи",
  Название: "Название",
  Описание: "Описание",
  "Срок задачи": "Срок задачи",
  "Нет срока": "Нет срока",
  помесячно: "помесячно",
  "по декадам": "по декадам",
  Период: "Период",
  "Тип отображения": "Тип отображения",
  "по нарастающей": "по нарастающей",
  "Скачать excel": "Скачать excel",
  "ФИО сотрудника": "ФИО сотрудника",
  "Всего баллов": "Всего баллов",
  "макс. бал.": "макс. бал.",
  Балл: "Балл",
  "максимум баллов": "максимум баллов",
  План: "План",
  Факт: "Факт",
  Разница: "Разница",
  Процент: "Процент",
  "1 - количество компаний на текущем этапе":
    "1 - количество компаний на текущем этапе",
  "(3) - количество компаний, прошедших данный этап":
    "(3) - количество компаний, прошедших данный этап",
  "Всего компаний": "Всего компаний",
  Подсказка: "Подсказка",
  "Войти с": "Войти с",
  "Единая система идентификации (OneID) предназначена для простого и безопасного доступа пользователей к различным информационным системам электронного правительства и государственных органов.":
    "Единая система идентификации (OneID) предназначена для простого и безопасного доступа пользователей к различным информационным системам электронного правительства и государственных органов.",
  Файл: "Файл",
  Удалить: "Удалить",

  "Прикрепленные файлы": "Прикрепленные файлы",
  "Прикрепить файл": "Прикрепить файл",
  "Загрузить файлы": "Загрузить файлы",
  "Прикрепил файл": "Прикрепил файл",
  "Прикреплённый файл удалён": "Прикреплённый файл удалён",
  "Добавление компании": "Добавление компании",
  "ИНН компании": "ИНН компании",
  "Например Tetra Park": "Например Tetra Park",
  "Направление деятельности": "Направление деятельности",
  "Страна учредителя": "Страна учредителя",
  "Сумма экспорта": "Сумма экспорта",
  "Прикрепить скриншот": "Прикрепить скриншот",
  "Категории тикетов": "Категории тикетов",
  "Все категории тикетов": "Все категории тикетов",
  "Добавить новую категорию тикета": "Добавить новую категорию тикета",
  "Наименование на узбекском": "Наименование на узбекском",
  "Наименование на русском": "Наименование на русском",
  "Наименование на английском": "Наименование на английском",
  "Дата завершения тикета": "Дата завершения тикета",
  "Создать категорию": "Создать категорию",
  "Категории часто задаваемых вопросов": "Категории часто задаваемых вопросов",
  "Все категории часто задаваемых вопросов":
    "Все категории часто задаваемых вопросов",
  "Добавить категорию часто задаваемых вопросов":
    "Добавить категорию часто задаваемых вопросов",
  Статьи: "Статьи",
  Шаблоны: "Шаблоны",
  Содержание: "Содержание",
  "Все статьи": "Все статьи",
  "Добавить новую статью": "Добавить новую статью",
  Категория: "Категория",
  "Создать статью": "Создать статью",
  "Изменить категорию": "Изменить категорию",
  "Редактировать статью": "Редактировать статью",
  "Все шаблоны": "Все шаблоны",
  "Добавить новый шаблон": "Добавить новый шаблон",
  "Редактировать шаблон": "Редактировать шаблон",
  "Статус изменен на status_name": "Статус изменен на <b>{status_name}</b>",
  "Приоритет изменен на name": "Приоритет изменен на <b>{name}</b>",
  "Изменён ответственный сотрудник с old на new":
    "Изменён ответственный сотрудник с <b>{old}</b> на <b>{new}</b>",
  "Срок задачи изменён с old на new":
    "Срок задачи изменён с <b>{old}</b> на <b>{new}</b>",
  Тикеты: "Тикеты",
  "Все тикеты": "Все тикеты",
  "Добавить новый тикет": "Добавить новый тикет",
  "Создание тикета": "Создание тикета",
  Вакант: "Вакант",
  Возраст: "Возраст",
  лет: "лет",
  "Дни рождения": "Дни рождения",
  "Дней осталось": "Дней осталось",
  Сегодня: "Сегодня",
  Завтра: "Завтра",
  "дней осталось": "дней осталось",
  Руководитель: "Руководитель",
  Специалист: "Специалист",
  Помощник: "Помощник",
  "Технический специалист": "Технический специалист",
  "Нет данных": "Нет данных",
  "Название отдела согласно штатному расписанию":
    "Название отдела согласно штатному расписанию",
  Заявитель: "Заявитель",
  Простой: "Simple",
  Отклоненный: "Rejected",
  Успешный: "Success",
  "Тип этапа": "Тип этапа",
  "Текущее подразделение": "Текущее подразделение",
  Структура: "Структура",
  "Загрузить лиды через Excel": "Загрузить лиды через Excel",
  Госнаграды: "Госнаграды",
  "Все госнаграды": "Все госнаграды",
  "У данного сотрудника пока отсутствуют госнаграды":
    "У данного сотрудника пока отсутствуют госнаграды.",
  "У данного сотрудника пока отсутствуют сертификаты":
    "У данного сотрудника пока отсутствуют сертификаты.",
  "Дата перевода": "Дата перевода",
  employed: "принят на работу",
  dismissed: "уволен",
  changed: "переведен на другую должность",
  "История передвижений не найдена": "История передвижений не найдена",
  "Информация не предоставлена": "Информация не предоставлена",
  "Создать госнаграду": "Создать госнаграду",
  "Редактировать госнаграду": "Редактировать госнаграду",
  "Удалить госнаграду": "Удалить госнаграду",
  Год: "Год",
  Комментарий: "Комментарий",
  "Электронная почта": "Электронная почта",
  Уведомления: "Уведомления",
  "Вам поступила новая задача в модуле TASKS":
    "Вам поступила новая задача в модуле TASKS",
  "Создана карточка, которая по названию имеет совпадение с ранее созданными другими карточками.":
    "Создана карточка <b>{name}</b>, которая по названию имеет совпадение с ранее созданными другими карточками.",
  "Карточка была переведена на этап Rejected":
    "Карточка <b>{company}</b> была переведена на этап Rejected <b>{name}</b>.",
  "Вы создаете карточку с компанией":
    "Вы создаете карточку с компанией, которая по названию имеет совпадение с ранее созданными другими карточками. Вы уверены, что хотите создать карточку с таким названием?",
  "Через 15 дней карточка переместится на этап Postponed":
    "Через 15 дней карточка переместится на этап Postponed",
  "Через 3 дня карточка будет скрыта с данного этапа":
    "Через 3 дня карточка будет скрыта с данного этапа",
  Понятно: "Понятно",
  "Данную карточку нельзя переместить на другие этапы, так как карточка уже добавлена в CRM систему.":
    "Данную карточку нельзя переместить на другие этапы, так как карточка уже добавлена в CRM систему.",
  "Чтобы переместить карточку на этап Lead, нужно сначала его взять в работу.":
    "Чтобы переместить карточку на этап Lead, нужно сначала его взять в работу.",
  "Карточку с данного этапа можно перевести только на этап Contact.":
    "Карточку с данного этапа можно перевести только на этап Contact.",
  "Карточку с данного этапа можно перевести только на этапы Lead и Rejected.":
    "Карточку с данного этапа можно перевести только на этапы Lead и Rejected.",
  "Карточку с данного этапа нельзя перевести на этап Contact.":
    "Карточку с данного этапа нельзя перевести на этап Contact.",
  Модуль: "Модуль",
  "Название карточки": "Название карточки",
  "Программа Local 2 Global": "Программа Local 2 Global",
  "Номер заявления": "Номер заявления",
  "Все регионы": "Все регионы",
  "Дата (от)": "Дата (от)",
  "Дата (по)": "Дата (по)",
  "Статус заявления": "Статус заявления",
  Очистить: "Очистить",
  "Количество заявок": "Количество заявок",
  "Дата подачи": "Дата подачи",
  Статус: "Статус",
  Черновик: "Черновик",
  "На расмотрении": "На расмотрении",
  "Отказано в рассмотрении": "Отказано в рассмотрении",
  "Возвращено на доработку": "Возвращено на доработку",
  Подтвержден: "Подтвержден",
  "Нет записей": "Нет записей",
  Подтвердить: "Подтвердить",
  Отозвать: "Отозвать",
  Скачать: "Скачать",
  "Сведения о компании": "Сведения о компании",
  "Дата регистрации/Номер регистрации": "Дата регистрации/Номер регистрации",
  "Юридический адрес": "Юридический адрес",
  "ФИО директора": "ФИО директора",
  Контакты: "Контакты",
  "Номер телефона директора": "Номер телефона директора",
  "Номер телефона ответственного лица": "Номер телефона ответственного лица",
  "Электронная почта для отправки уведомлений":
    "Электронная почта для отправки уведомлений",
  "Тип компании": "Тип компании",
  Сервисная: "Сервисная",
  "Виды разрабатываемого программного обеспечения":
    "Виды разрабатываемого программного обеспечения",
  "Языки программирования": "Языки программирования",
  "Базы данных": "Базы данных",
  Фреймворки: "Фреймворки",
  Продуктовая: "Продуктовая",
  "Краткое описание продукта": "Краткое описание продукта",
  "Предназначение продукта": "Предназначение продукта",
  "Образовательное учреждение": "Образовательное учреждение",
  "Перечень образовательных курсов": "Перечень образовательных курсов",
  "Наличие филиалов": "Наличие филиалов",
  Консалтинговая: "Консалтинговая",
  "Хостинг и регистратор доменов": "Хостинг и регистратор доменов",
  Кибербезопасность: "Кибербезопасность",
  "Деятельность венчурного финансирования и акселерации":
    "Деятельность венчурного финансирования и акселерации",
  "Услуги киберспорта": "Услуги киберспорта",
  "Услуги в сфере мультимедиа и дизайна":
    "Услуги в сфере мультимедиа и дизайна",
  "Количество филиалов": "Количество филиалов",
  Специальность: "Специальность",
  "Количество единиц": "Количество единиц",
  "Сохранить как черновик": "Сохранить как черновик",
  "Информация о сотрудниках": "Информация о сотрудниках",
  "Финансовая информация": "Финансовая информация",
  "Доход от экспортных операций в течение последнего года":
    "Доход от экспортных операций в течение последнего года",
  usd: "USD",
  "Список стран, куда был выполнен экспорт услуг/продуктов":
    "Список стран, куда был выполнен экспорт услуг/продуктов",
  "Доход от оказанных услуг в течение последнего года":
    "Доход от оказанных услуг в течение последнего года",
  "тыс. сум": "тыс. сум",
  Есть: "Есть",
  "Краткое описание деятельности компании":
    "Краткое описание деятельности компании",
  "Описание продукта/сервиса": "Описание продукта/сервиса",
  "Ключевые достижения компании": "Ключевые достижения компании",
  "Почему вы хотите принять участие в программе Local2Global?":
    "Почему вы хотите принять участие в программе Local2Global?",
  "Загрузить портфолио (в формате pdf, ppt не более 15 МБ)":
    "Загрузить портфолио (в формате pdf, ppt не более 15 МБ)",
  "Дополнительная информация": "Дополнительная информация",
  "История действий по заявлению": "История действий по заявлению",
  Исполнитель: "Исполнитель",
  "Дата действия": "Дата действия",
  "Статус действия": "Статус действия",
  "на русском": "на русском",
  "на узбекском": "на узбекском",
  "на английском": "на английском",
  Отменить: "Отменить",
  Внимание: "Внимание",
  "Заявки на участие": "Заявки на участие",
  "Заявки на покрытие расходов": "Заявки на покрытие расходов",
  "Дата начала договора (от)": "Дата начала договора (от)",
  "Дата начала договора (до)": "Дата начала договора (до)",
  "Возраст (от)": "Возраст (от)",
  "Возраст (до)": "Возраст (до)",
  "Гражданин Узбекистана": "Гражданин Узбекистана",
  "Иностранный гражданин": "Иностранный гражданин",
  "Выберите сотрудника": "Выберите сотрудника",
  "Передать в Экспертный совет": "Передать в Экспертный совет",
  "Передано в Экспертный совет": "Передано в Экспертный совет",
  "Экспертный совет рассмотрел": "Экспертный совет рассмотрел",
  "Результаты рассмотрения Экспертного совета":
    "Результаты рассмотрения Экспертного совета",
  "Член Экспертного совета": "Член Экспертного совета",
  "Дата и время решения": "Дата и время решения",
  Отклонён: "Отклонён",

  Степень: "Степень",
  Бакалавр: "Бакалавр",
  Магистратура: "Магистратура",
  "Название учебного заведения": "Название учебного заведения",
  "Дата начала: месяц, год": "Дата начала: месяц, год",
  "Дата начала": "Дата начала",
  "Дата окончания: месяц, год": "Дата окончания: месяц, год",
  "Дата окончания": "Дата окончания",
  Специализация: "Специализация",
  Таблица: "Table",
  "Внесенные данные": "Entered data",
  "Внести данные": "Внести данные",
  "Внесение данных": "Внесение данных",
  "Имеется ли сотрудник со знанием английского языка? (Да/Нет)":
    "Имеется ли сотрудник со знанием английского языка? (Да/Нет)",
  "Изменение внесения данных": "Изменение внесения данных",
  "Внесенные данные в Local team": "Внесенные данные в Local team",
  "Внесенные данные в Infrastructure": "Внесенные данные в Infrastructure",
  "Название офиса": "Название офиса",
  "Площадь офиса": "Площадь офиса",
  "Аренда ($/m2)": "Аренда ($/m2)",
  "Мебель": "Мебель",
  "Оборудование": "Оборудование",
  "Локация офиса": "Локация офиса",
  "Район": "Район",
  "Предоставил IT Park": "Предоставил IT Park",
  "Предоставил хокимият": "Предоставил хокимият",
  "Подтвержденные данные": "Confirmed data",
  "Внесенные данные в Talents": "Внесенные данные в Talents",
  "Подтвержденные данные в Talents": "Подтвержденные данные в Talents",
  soft_skills: "Soft skills",
  hard_skills: "Hard skills",
  languages: "Languages",
  'Запросы в отдел Softlanding': 'Запросы в отдел Softlanding',
  'Перевести в отдел Softlanding': 'Перевести в отдел Softlanding',
  'Направлен запрос в отдел Softlanding': 'Направлен запрос в отдел Softlanding',
  'Изменить этап': 'Изменить этап',
  'Редактирование этапа': 'Редактирование этапа',
  'Турецкий': 'Турецкий',
  
  "Направление заявки на возмещения":"Направление заявки на возмещения",
  "Заявка на возмещение расходов по программе Local2Global":"Заявка на возмещение расходов по программе Local2Global",
  "Выберите направление для возмещения расходов на которую вы подаете заявку":"Выберите направление для возмещения расходов на которую вы подаете заявку",
  "Предоставьте вашу контактную информацию":"Предоставьте вашу контактную информацию",
  "Имя пользователя Telegram":"Имя пользователя Telegram",
  "Имя пользователя WhatsApp":"Имя пользователя WhatsApp",
  "Предоставьте информацию о поездке":"Предоставьте информацию о поездке",
  "Даты поездки":"Даты поездки",
  "Пункт назначения":"Пункт назначения",
  "Цель поездки":"Цель поездки",
  "Предоставьте информацию о мероприятии":"Предоставьте информацию о мероприятии",
  "Название мероприятия":"Название мероприятия",
  "Даты участия":"Даты участия",
  "Цель участия":"Цель участия",
  "Предоставьте информацию о консультанте":"Предоставьте информацию о консультанте",
  "Имя консультанта/специалиста":"Имя консультанта/специалиста",
  "Объем работы":"Объем работы",
  "Сроки сотрудничества":"Сроки сотрудничества",
  "Предоставьте информацию о гостях":"Предоставьте информацию о гостях",
  "Имя гостя":"Имя гостя",
  "Цель визита":"Цель визита",
  "Даты пребывания":"Даты пребывания",
  "Загрузите документы (в формате docx, pdf не более 15 МБ)":"Загрузите документы (в формате docx, pdf не более 15 МБ)",
  
  'The route of the trip':'Маршрут поездки',
  'Plane tickets and boarding passes':'Билеты на самолет и посадочные талоны',
  'Receipts for accommodation':'Квитанции за проживание',
  'Detailed expense report':'Детализированный отчет о расходах',
  'Meeting minutes/reports':'Протоколы встреч/отчеты',
  'Prospective contracts or Memoranda of Understanding (MOU)':'Перспективные контракты или Меморандумы о взаимопонимании (MOU)',
  'Upload the contract or employment contract of the person who is participating in the event.':'Загрузите контракт или трудовой договор человека, который участвует в мероприятии. ',
  
  'Confirmation of registration for the conference/event':'Подтверждение регистрации на конференцию/мероприятие',
  'Receipts for payment of registration fees':'Квитанции об оплате регистрационных взносов',
  'The program of the event':'Программа мероприятия',
  'Plane tickets and boarding passes':'Билеты на самолет и посадочные талоны',
  'Stand/place rental agreement (if applicable)':'Договор аренды стенда/места (если применимо)',
  'Post-event report (including photos and videos)':'Отчет после мероприятия (включая фотографии и видео)',
  'Upload the contract or employment contract of the person who is participating in the event.':'Загрузите контракт или трудовой договор человека, который участвует в мероприятии.',

  'Contract for the provision of consulting services':'Договор на оказание консультационных услуг',
  "Consultant's qualifications (resume, portfolio, etc.)":"Квалификация консультанта (резюме, портфолио и т.д.)",
  'Invoices from a consultant/specialist':'Счета от консультанта/специалиста',
  'Confirmation of payment (receipts or bank statements)':'Подтверждение оплаты (квитанции или выписки из банка)',
  'Reports and analysis (photos, videos, strategies and action plans)':'Отчеты и анализ (фотографии, видео, стратегии и планы действий)',
  "Upload the consultant's contract or agreement, which specifies the scope of work.":"Загрузите договор или соглашение консультанта, в котором указаны объем работы.",

  'Official invitations':'Официальные приглашения',
  "Guest profiles":"Профили гостей",
  'Receipts for air tickets and boarding passes':'Квитанции за авиабилеты и посадочные талоны',
  'Receipts for accommodation':'Квитанции за проживание',
  'Prospective contracts or Memoranda of Understanding (MOU)':'Перспективные контракты или Меморандумы о взаимопонимании (MOU)',
  "Upload the contract or employment contract of the person who is responsible for organizing the visit.":"Загрузите контракт или трудовой договор человека, который отвечает за организацию визита.",
  
  "Ваши заявки на возмещение":"Ваши заявки на возмещение",
  "Основные сведения":"Основные сведения",
  "Отчетная документация":"Отчетная документация",
  "Сохраните введенные данные в калькуляторе перед тем, как отправить заявление Экспертной комиссии.":"Сохраните введенные данные в калькуляторе перед тем, как отправить заявление Экспертной комиссии.",
};
