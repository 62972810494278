import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return method("crm.steps.list", params);
  },
  getListWithCards({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.steps.list-cards", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSoftLandingCards({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.steps.list-softlanding-cards", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getLeadList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.lead-steps.list-cards", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LEAD_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateLeadStep({ commit }, data) {
    return method("crm.lead-cards.update", data);
  },
  getCardsCount({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.cards.count", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_CARDS_COUNT", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCountsSoftLanding({ commit }, data) {
    return method("crm.cards.softlanding-cards-count", data);
  },
  create({ commit }, data) {
    return method("crm.steps.create", data);
  },
  createForSoftLanding({ commit }, data) {
    return method("crm.steps.create-softlanding-step", data);
  },
  setDragStepIndex({ commit }, index) {
    commit("SET_DRAG_STEP_INDEX", index);
  },
  update({ commit }, data) {
    return method("crm.steps.update", data);
  },
  updateForSoftLanding({ commit }, data) {
    return method("crm.steps.update-softlanding-step", data);
  },
  show({ commit }, data) {
    return method("crm.steps.show", data);
  },
  destroy({ commit }, data) {
    return method("crm.steps.delete", data);
  },
  shortlist({ commit }, data) {
    return method("crm.steps.shortlist", data);
  },
  updateOrders({ commit }, data) {
    return method("crm.steps.update-orders", data);
  },
  getCompanyList({ commit }, data) {
    return new Promise((resolve, reject) => {
      method("crm.company.find", data)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_COMPANY_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  kpiSteps({ commit }, data) {
    // return method("crm.kpi.steps", data);
    return new Promise((resolve, reject) => {
      method("crm.kpi.steps", data)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_KPI", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
