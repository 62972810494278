const routes = [
  {
    path: "/local-to-global",
    name: "LocalToGlobal",
    meta: { middleware: "Auth" },
    component: () => import("@/views/local-global/Index.vue"),
  },
  {
    path: "/local-to-global/:uuid",
    name: "LocalToGlobalShow",
    meta: { middleware: "Auth" },
    component: () => import("@/views/local-global/Show.vue"),
  },
  {
    path: "/local-to-global/news/index",
    name: "LocalToGlobalNews",
    meta: { middleware: "Auth" },
    component: () => import("@/views/local-global/News.vue"),
  },
];

export default routes;
