export default {
  "Вход в кабинет": "Вход в кабинет",
  departments: "Bo'limlar",
  ПИНФЛ: "ЖШШИР",
  all_departments: "Barcha Bo'limlar",
  "Тикеты": "Тикеты",
  "Все тикеты": "Все тикеты",
  "Добавить новый тикет": "Добавить новый тикет",
  "Создание тикета":"Создание тикета",
  
  "Направление заявки на возмещения":"Харажатларни қоплаш учун ариза йўналиши",
  "Заявка на возмещение расходов по программе Local2Global":"Local2Global дастури бўйича харажатларни қоплаш учун ариза",
  "Выберите направление для возмещения расходов на которую вы подаете заявку":"Харажатларни қоплаш учун ариза топшираётган йўналишни танланг",
  "Предоставьте вашу контактную информацию":"Контакт маълумотларингизни тақдим этинг",
  "Имя пользователя Telegram":"Telegram фойдаланувчиси номи",
  "Имя пользователя WhatsApp":"WhatsApp фойдаланувчи номи",
  "Предоставьте информацию о поездке":"Сафар ҳақида маълумот беринг",
  "Даты поездки":"Сафар санаси",
  "Пункт назначения":"Бориш манзили",
  "Цель поездки":"Сафар мақсади",
  "Предоставьте информацию о мероприятии":"Тадбир ҳақида маълумот беринг",
  "Название мероприятия":"Тадбир номи",
  "Даты участия":"Иштирок санаси",
  "Цель участия":"Иштирок этиш мақсади",
  "Предоставьте информацию о консультанте":"Маслаҳатчи ҳақида маълумот беринг",
  "Имя консультанта/специалиста":"Консултант/мутахассиснинг исми",
  "Объем работы":"Иш ҳажми",
  "Сроки сотрудничества":"Ҳамкорлик муддати",
  "Предоставьте информацию о гостях":"Меҳмонлар ҳақида маълумот беринг",
  "Имя гостя":"Меҳмоннинг исми",
  "Цель визита":"Ташриф мақсади",
  "Даты пребывания":"Яшаш санаси",
  "Загрузите документы (в формате docx, pdf не более 15 МБ)":"Ҳужжатларни юкланг (доcх, пдф форматида кўпи билан 15 МБ)",
  
  'The route of the trip':"Сафар маршрути",
  'Plane tickets and boarding passes':"Турар жой квитансиялари",
  'Receipts for accommodation':"Учрашувлар баённомалари/ҳисоботлар",
  'Detailed expense report':"Самолёт чипталари ва қўниш талонлари",
  'Meeting minutes/reports':"Харажатлар тўғрисида батафсил ҳисобот",
  'Prospective contracts or Memoranda of Understanding (MOU)':"Шартномалар ёки Англашув Меморандумлари (МОУ)",
  'Upload the contract or employment contract of the person who is participating in the event.':"Сафар учун масъул бўлган шахснинг шартномаси ёки меҳнат шартномасини юкланг",
  
  'Confirmation of registration for the conference/event':"Конференсия/тадбирга рўйхатдан ўтганликни тасдиқлашти",
  'Receipts for payment of registration fees':"Рўйхатга олиш бадаллари тўланганлиги тўғрисидаги квитансиялар",
  'The program of the event':"Тадбир дастури",
  'Plane tickets and boarding passes':"Самолёт чипталари ва қўниш талонлари",
  'Stand/place rental agreement (if applicable)':"Стенд/жой ижараси шартномаси (агар қўлланилса)",
  'Post-event report (including photos and videos)':"Тадбирдан кейинги ҳисобот (шу жумладан фотосуратлар ва видеолар)",
  'Upload the contract or employment contract of the person who is participating in the event.':"Тадбирда иштирок этаётган шахснинг шартномаси ёки меҳнат шартномасини юкланг.",

  'Contract for the provision of consulting services':"Маслаҳат хизматлари кўрсатиш шартномаси",
  "Consultant's qualifications (resume, portfolio, etc.)":"Консултантнинг малакаси (резюме, портфолио ва бошқалар)",
  'Invoices from a consultant/specialist':"Консултант/мутахассис ҳисобварақлари",
  'Confirmation of payment (receipts or bank statements)':"Тўловни тасдиқлаш (квитансия ёки банкдан кўчирма)",
  'Reports and analysis (photos, videos, strategies and action plans)':"Ҳисоботлар ва таҳлиллар (суратлар, видеолар, стратегиялар ва ҳаракатлар режалари)",
  "Upload the consultant's contract or agreement, which specifies the scope of work.":"Консултантнинг иш ҳажми кўрсатилган шартнома ёки келишувини юкланг.",

  'Official invitations':"Расмий таклифлар",
  "Guest profiles":"Меҳмон профиллари",
  'Receipts for air tickets and boarding passes':"Авиачипталар ва қўниш талонлари учун квитансиялар",
  'Receipts for accommodation':"Яшаш учун квитансиялар",
  'Prospective contracts or Memoranda of Understanding (MOU)':"Шартномалар ёки Англашув Меморандумлари (МОУ)",
  "Upload the contract or employment contract of the person who is responsible for organizing the visit.":"Ташрифни ташкил этиш учун масъул бўлган шахснинг шартномаси ёки меҳнат шартномасини юкланг.",
  
  "Ваши заявки на возмещение":"Харажатларни қоплаш учун аризаларингиз",
  "Основные сведения":"Асосий маълумотлар",
  "Отчетная документация":"Ҳисобот ҳужжатлари",
};
