import { method } from "@/api/api";

export const actions = {
  getList({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.local2global.list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getRegions({ commit }, params) {
    return new Promise((resolve, reject) => {
      method("crm.local2global.regions", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_REGIONS", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  searchResidentByName({ commit }, params) {
    return method("crm.local2global.search-company-name", params);
  },

  getSingle({ commit }, params) {
    return method("crm.local2global.show", params);
  },
  returnForIncomplete({ commit }, params) {
    return method("crm.local2global.return-for-incomplete", params);
  },
  confirm({ commit }, params) {
    return method("crm.local2global.confirm", params);
  },
  rejectFromReview({ commit }, params) {
    return method("crm.local2global.reject-from-review", params);
  },
  sendCommissionReview({ commit }, params) {
    return method("crm.local2global.send-commission-review", params);
  },
  commissionReview({ commit }, params) {
    return method("crm.local2global.commission-review", params);
  },
  saveCalculation({ commit }, params) {
    return method("crm.local2global.calculation", params);
  },
  newslist({ commit }, params) {
    params['type'] = 'l2g';
    return new Promise((resolve, reject) => {
      method("crm.short-news.get-list", params)
        .then((res) => {
          if (res["success"] && res["data"]) {
            commit("SET_NEWS_LIST", res["data"]);
          }
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  createNews({ commit }, params) {
    params['type'] = 'l2g';
    console.log("createNews" , params)
    return method("crm.short-news.create", params);
  },
  editNews({ commit }, params) {
    if(!params['image']){
      delete params['image'];
    }
    return method("crm.short-news.edit", params);
  },
  deleteNews({ commit }, params) {
    return method("crm.short-news.delete", params);
  },

};
