export const state = {
  list: [],
  pagination: null,
  news: [],
  newsPagination: null,
  regions: [],
  directions: [{
    id:1,
    name_en:"Business trips",
    name_oz:"Xorijiy davlatlarga xizmat safar",
    name_ru:"Деловые поездки",
  },{
    id:2,
    name_en:"Participation in conferences/forums",
    name_oz:"Konferensiya/forumlarda ishtirok etish",
    name_ru:"Участие в конференциях/форумах",
  },{
    id:3,
    name_en:"Hiring consultants/specialists",
    name_oz:"Maslahatchi/mutaxassislarni yollash",
    name_ru:"Наем консультантов/специалистов",
  },{
    id: 4,
    name_en: "Reception of international guests",
    name_oz: "Xalqaro mehmonlarni qabul qilish",
    name_ru: "Прием международных гостей",
  }]
};
